<template>
  <div class="select-search">
    <Input v-on:keyup="onSearch" type="text" :placeholder="label" :multiple="multiple" :value="value"/>
    <div class="select-search-result scroller">
      <div class="select-search-option" v-for="(item, key) in filterData" @click="onHandleOption"
           :key="key" :option-id="item[0]" :option-value="item[1]"
      >{{ item[1] }}
      </div>
    </div>
  </div>
</template>

<script>
import Input from "@/components/elements/FilterInput";

export default {
  name: "SelectSearch",
  components: {Input},
  props: {
    selectData: Array,
    multiple: String,
    label: String
  },
  data() {
    return {
      filterData: [],
      value: ''
    }
  },
  methods: {
    onSearch(e) {
      if (e.target.value === '') {
        this.filterData = []
      } else {
        this.filterData = this.selectData.filter(function (item) {
                if (item[1]) {
                  return item[1].toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
                } else {
                  return item[2].toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
                }
            }
        )
      }
    },
    onHandleOption(e) {
      this.value = e.target.getAttribute('option-value')
      this.filterData = []
      this.$emit('setOption', e)
    },
  }
}
</script>

<style scoped>
.select-search-result {
  max-height: 20em;
}

.select-search-option {
  padding: .2em;
  cursor: pointer;
}
</style>
