<template>
  <label v-if="label" :for="'checkbox' + id" @click="toggleCheck"> {{label}}
    <input type="checkbox" :placeholder="placeholder" :id="'checkbox' + id" :name="parent_id"
           :aria-label="label" :style='css_style' />
  </label>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    label: String,
    placeholder: String,
    parent_id: Number,
    id: Number,
    multiple: Number,
    image: String,
    MediaURL: String
  },
  emits: ['setCheckbox'],
  data() {
    return {
      css_style: ''
    }
  },
  mounted() {
    this.css_style += 'font-size:' + this.multiple + 'em;'
  },
  methods: {
    toggleCheck(e) {
      e.target.classList.toggle('active')
      this.$emit('setCheckbox', e)
    }
  }
}

</script>

<style scoped>
  label {
    display: inline-block;
    font-size: 1.1em;
    padding: 0.2em 0.5em 0.2em 2em;
    line-height: 1.8em;
    position: relative;
    cursor: pointer;
    width: 100%;
  }
  label:hover {
    color: var(--accent);
  }
  label:before {
    left: 0;
    position: absolute;
    content: '';
    background-color: var(--bg_main);
    border: 1px solid var(--text_base);
    width: 24px;
    height: 24px;
  }
  label:hover:before {
    border: 1px solid var(--accent);
  }
  label::after {
    font-family: 'Material Icons';
    left: 0;
    top: 0;
    position: absolute;
    content: '';
    color: var(--white);
    font-size: 30px;
  }
  label.active::after {
    content: 'check';
  }
  img {
    max-width: 100%;
    height: auto;
  }
  input {
    display: none;
  }
</style>
