<template>
  <router-link
      :class="{'router-link-active': $route.fullPath ==='/'}"
      :to="{name: 'Models', params: {
          lang: lang,
          MediaURL: MediaURL,
          filter:filter,
          size: size,
          reset: reset,
          step: step
        }}">
    <span v-if="lang === 'ru'">Модели</span>
    <span v-if="lang === 'en'">Models</span>
  </router-link>
  <router-link to="/supplies">
    <span v-if="lang === 'ru'">Расходные материалы</span>
    <span v-if="lang === 'en'">Supplies</span>
  </router-link>
  <router-link to="/about">
    <span v-if="lang === 'ru'">Информация</span>
    <span v-if="lang === 'en'">Info</span>
  </router-link>
</template>

<script>

export default {
  name: "Menu",
  props: {
    lang: String,
    MediaURL: String,
    filter: Object,
  },
  data() {
    return {
      size: 60,
      reset: 'false',
      step: 'next'
    }
  }
}
</script>

<style scoped>
a {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  color: var(--white);
  text-decoration: none;
  font-size: 1.6em;
  font-weight: 400;
  width: 100%;
  box-sizing: border-box;
  padding: 0.7em 0.8em 0;
}

a:hover {
  color: var(--accent);
}

a.router-link-active {
  color: var(--white);
  border-radius: 0.3em;
  background-color: var(--primary);
  background-image: linear-gradient(to right, var(--primary), var(--primary), var(--primary), var(--bg_main));
  background-size: 100%;
  padding: 0.5em 0.8em;
}

a:nth-child(1) {
  grid-area: a-first;
}
a:nth-child(2) {
  grid-area: a-third;
}
a:nth-child(3) {
  grid-area: a-fourth;
}
</style>
