<template>
  <label v-if="label" :for="id"> {{ label }}
    <input @input="$emit('update:modelValue', $event.target.value)"
           :value="modelValue" :type="type" :placeholder="placeholder"
           :id="id" :name="id" :aria-label="label" :style='css_style'/>
  </label>
  <input v-else @input="$emit('update:modelValue', $event.target.value)"
         :value="modelValue" :type="type" :placeholder="placeholder"
         :id="id" :name="id" :aria-label="label" :style='css_style'/>
</template>

<script>
export default {
  name: "Input",
  props: {
    label: String,
    placeholder: String,
    type: String,
    id: String,
    multiple: String,
    modelValue: String
  },
  emits: ['update:modelValue'],
  data() {
    return {
      css_style: ''
    }
  },
  mounted() {
    this.css_style += 'font-size:' + this.multiple + 'em;'
  },
  methods: {
    changeValue(title) {
      this.$emit('update:modelValue', title) // previously was `this.$emit('input', title)`
    }
  }
}

</script>

<style scoped>
input {
  border-radius: 0.3em;
  border: 1px solid var(--text_base);
  padding: 0.4em 0.5em;
  line-height: 1em;
  width: 100%;
  min-width: 200px;
  display: inline-block;
  background-color: var(--bg_section);
  box-sizing: border-box;
  color: var(--input-text) !important;
}
</style>
