<template>
  <div class="search-bar">
    <Input  v-if="lang === 'ru'" id="search" placeholder="Поиск" type="text" multiple="1.5" v-on:keyup="search" />
    <Input  v-if="lang === 'en'" id="search" placeholder="Search" type="text" multiple="1.5" v-on:keyup="search" />
    <div v-if="SearchResult.length > 1 && message" id="search_result">
      <div v-for="item in SearchResult" class="search_result" :key=item>
        <div class="image">
          <img v-bind:src="MediaURL + item[9]" v-if="item[9]" alt="image">
          <img v-bind:src="MediaURL + item[10]" v-if="item[10]" alt="image">
          <img v-bind:src="MediaURL + 'no_image.svg'" style="width: 100%;" v-if="!item[10] && !item[9]" alt="image">
        </div>
        <div class="desc">
          <h2><a v-bind:href="'/partcode/' + item[2]">
            {{ item[4] }}
            {{ item[3] }}
          </a></h2>
          <div class="rating">
            <span v-if="lang === 'ru'">Рейтинг</span>
            <span v-if="lang === 'en'">Rating</span>
            <div class="stars">
              <img v-bind:src="MediaURL + 'icons/star.svg'" alt="star" />
              <img v-bind:src="MediaURL + 'icons/star_o.svg'" alt="star" />
              <img v-bind:src="MediaURL + 'icons/star_o.svg'" alt="star" />
              <img v-bind:src="MediaURL + 'icons/star_o.svg'" alt="star" />
              <img v-bind:src="MediaURL + 'icons/star_o.svg'" alt="star" />
            </div>
          </div>
          <div class="search_result_partcode">
            <span v-if="item[5]">Part code: {{ item[5] }}</span>
          </div>
          <div v-if="item[11]" class="search_result_price">
            <span v-if="lang === 'ru'">Цена: </span>
            <span v-if="lang === 'en'">Price: </span>
            <span>{{ toPrice(item[11]) }} &#x20bd;</span>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Input from "@/components/elements/FilterInput";
export default {
  name: "SearchApp",
  components: {Input},
  props: {
    lang: String,
    message: String,
    SearchResult: Array,
    MediaURL: String
  },
  methods: {
    search(e) {
      this.$emit('searchItem', e.target.value)
    },
    toPrice(val) {
      const proxy1 = new Proxy(val, {});
      const prices = {...proxy1}[0].split('~')
      console.log(prices)
      // const mask = price.replace(',00', '')
      let price = prices[1]
      return price
    },
    aside() {
      this.$emit('showAside')
    }
  }
}

</script>

<style scoped>

</style>
