<template>
  <div class="filter-bar">
    <Button v-for="item in inputs" :key="item[0]" :iid="item[0]" :label="item[1]" :val="item[2]" :vmin="item[4]"
            :vmax="item[5]" :lang="lang" :multiple="1.2" />
  </div>
</template>

<script>
import Button from "@/components/elements/Button";
export default {
  name: "FilterBar",
  components: {Button},
  props: {
    lang: {type: String},
    inputT: {type: Event},
  },
  data() {
    return {
      inputs: []
    }
  },
  methods: {
    checkArray(arr, elem) {
      for (let i = 0; i < arr.length; i++) {
        console.log(arr[i][0])
        if (arr[i][0] === elem) {
          return i
        }
      }
      return -1
    },
    inputChange(e) {
      console.log(e.target)
      let o_id = e.target.id
      const name = e.target.getAttribute('aria-label')
      const t = e.target.getAttribute('name')
      let val = ''
      let itype = 'checkbox'
      if (t.indexOf('radio') > -1) {
        itype = 'radio'
        o_id = t
        val = e.target.getAttribute('aria-describedby')
        const idx = this.checkArray(this.inputs, o_id)
        if (this.inputs.length > 0 && idx >= 0) {
          this.inputs[idx] = [o_id, name, val, itype]
        } else {
          this.inputs.push([o_id, name, val, itype])
        }
      } else if (t.indexOf('range') > -1) {
        itype = 'range'
        o_id = t
        val = e.target.value
        let vmin
        let vmax
        if (e.target.getAttribute('id').indexOf('min') > -1) {
          vmin = e.target.value
        } else {
          vmax = e.target.value
        }
        const idx = this.checkArray(this.inputs, o_id)
        if (this.inputs.length > 0 && idx >= 0) {
          if (vmin) {
            vmax = this.inputs[idx][5]
          } else {
            vmin = this.inputs[idx][4]
          }
          this.inputs[idx] = [o_id, name, val, itype, vmin, vmax]
        } else {
          this.inputs.push([o_id, name, val, itype, vmin, vmax])
        }
      } else {
        const idx = this.checkArray(this.inputs, o_id)
        if (this.inputs.length > 0 && idx >= 0) {
          this.inputs.splice(idx, 1)
        } else {
          this.inputs.push([o_id, name, val, itype])
        }
      }
    }
  },
  computed: {

  },
  watch: {
    inputT() {
      this.inputChange(this.inputT)
    }
  }
}
</script>

<style scoped>
.filter-bar {
  padding: .5em;
}

</style>
