<template>
  <div class="btn cart">
    <span v-if="cart_count > 0" class="cart_badge">{{ cart_count }}</span>
    <router-link to="/cabinet">
      <span class="material-icons-outlined">shopping_cart</span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "CartBlock",
  props: {
    cart_count: Number
  }
}
</script>

<style scoped>
a {
  color: var(--white);
}
.cart {
  position: relative;
}
.cart_badge {
  position: absolute;
  right: 0;
  bottom: -0.3em;
  color: var(--white);
  font-weight: 500;
  border-radius: .5em;
  background: var(--warning);
  padding: 0 0.2em;
  text-align: center;
}
</style>
