import axios from "axios"

// const ApiUrl = 'http://localhost:8008/'
const ApiUrl = 'http://116.203.219.63:8008/'
const FormData = require('form-data');
// const ApiUrl = 'http://192.168.66.199:8080/'


export function fetchModel(id) {
    let data
    axios.get(ApiUrl + "model?id=" + id)
        .then(response => {
            console.log("response", response.data)
            data = response.data
        })
    return data
}


export function fetchModels(limit, offset, brand_id) {
    if (brand_id == undefined) brand_id = null
    return axios.get(ApiUrl + "models?limit=" + limit + '&offset=' + offset + '&brand_id=' + brand_id)
        .then(response => {
            return response.data
        }).catch(function (error) {
                console.log(error)
                return error
            }
        );
}


export function fetchModules() {
    return axios.get(ApiUrl + "modules")
        .then(response => {
            return response.data
        }).catch(function (error) {
                console.log(error)
                return error
            }
        );
}

export function fetchDictionaryPartcodes() {
    return axios.get(ApiUrl + "dictionary_partcodes")
        .then(response => {
            return response.data
        }).catch(function (error) {
                console.log(error)
                return error
            }
        );
}

export function fetchPartcode(id) {
    return axios.get(ApiUrl + "partcode?id=" + id)
        .then(response => {
            return response.data
        }).catch(function (error) {
                console.log(error)
                return error
            }
        );
}

export function fetchFilter() {
    return axios.get(ApiUrl + "filter")
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            console.log(error)
            return error
        });
}

export function fetchBrands() {
    return axios.get(ApiUrl + "brands")
        .then(response => {
            return response.data
        }).catch(function (error) {
            console.log(error)
            return error
        });
}


export function filter_models(filter, reset, step) {
    let data = new FormData();
    data.append('checkboxs', JSON.stringify(filter['checkboxs']));
    data.append('ranges', JSON.stringify(filter['ranges']));
    data.append('radios', JSON.stringify(filter['radios']));
    data.append('brands', JSON.stringify(filter['brands']));
    data.append('reset', reset);
    data.append('step', step);
    let config = {
        method: 'post',
        url: ApiUrl + 'models',
        headers: {},
        data: data
    };
    return axios(config)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            console.log(error)
            return error
        });
}

export function search(t, q) {
    let config = {
        method: 'get',
        url: ApiUrl + "search?t=" + t + '&q=' + q,
        headers: {}
    };
    return axios(config)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            console.log(error);
        });
}


export function savePartcode(data) {
    console.log(data)
    let fdata = new FormData();
    fdata.append('fdata', JSON.stringify(data));
    let config = {
        method: 'post',
        data: fdata,
        url: ApiUrl + "partcode",
        headers: {}
    };
    return axios(config)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            console.log(error);
        });
}
