<template>
  <input type="number" :name="'range' + parent_id" :placeholder="rvals[0]['min']" :id="'min' + parent_id"
         :min="rvals[0]['min']" :max="rvals[1]['max']" :aria-label="label" :style='css_style' @change="setNumber" />
  <input type="number" :name="'range' + parent_id" :placeholder="rvals[1]['max']" :id="'max' + parent_id"
         :min="rvals[0]['min']" :max="rvals[1]['max']" :aria-label="label" :style='css_style' @change="setNumber" />
</template>

<script>
export default {
  name: "Numbers",
  props: {
    lang: String,
    label: String,
    parent_id: Number,
    rvals: Array,
    multiple: Number,
    image: String,
    MediaURL: String
  },
  emits: ['setRange'],
  data() {
    return {
      css_style: '',
      value: this.rvals[0]['min']
    }
  },
  mounted() {
    this.css_style += 'font-size:' + this.multiple + 'em;'
  },
  methods: {
    setNumber(e) {
      this.$emit('setRange', e)
    }
  }
}

</script>

<style scoped>
input {
  border: 1px solid var(--text_base);
  background-color: var(--bg_main);
  width: 5em;
  padding: 0.25em 0.3em;
  color: var(--white);
  margin: .2em .5em .2em 0;
}
input::placeholder, input::-webkit-input-placeholder {
  color: var(--input-text);
}
</style>
