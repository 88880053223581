<template>
  <div class="model_filter scroller">

    <div v-if="loading" class="loading">
      Загрузка...
    </div>

    <div v-if="error" class="error">
      {{ error }}
    </div>
    <div class="filter_brands">
      <CheckboxImaged v-for="item in Brands" :key="item[0]" :label="item[1]" :id="item[0]"
                      :MediaURL="MediaURL" :image="'brands/' + item[2]" @click="setBrand($event)" />
    </div>

    <div v-if="filterSettings" class="filter_options">
      <slot v-for="item in filterSettings" :key="item[0]">
        <slot v-if="item[4] === 'checkbox' && lang === 'ru'">
          <h3>{{ item[2] }}</h3>
          <Checkbox v-for="ch in item[5]" :key="ch[0]" :label="ch['val']" :id="ch['id']" :parent_id="item[3]"
                    :multiple="1" @setCheckbox="setCheckbox($event)" />
        </slot>
        <slot v-if="item[4] === 'checkbox' && lang === 'en'">
          <h3>{{ item[7] }}</h3>
          <Checkbox v-for="ch in item[9]" :key="ch[0]" :label="ch['val']" :id="ch['id']" :parent_id="item[3]"
                    :multiple="1" @setCheckbox="setCheckbox($event)" />
        </slot>
        <slot v-if="item[4] === 'radio'">
          <h3 v-if="lang === 'ru'">{{ item[2] }}</h3>
          <h3 v-if="lang === 'en'">{{ item[7] }}</h3>
          <div class="input_inline">
            <Radio :rvals="item[5]" :key="item[3]" :parent_id="item[3]" :lang="lang" :multiple="1" :label="item[2]"
                   @setRadio="setRadio($event)" />
          </div>
        </slot>
        <slot v-if="item[4] === 'range'">
          <h3 v-if="lang === 'ru'">{{ item[2] }}</h3>
          <h3 v-if="lang === 'en'">{{ item[7] }}</h3>
          <div class="input_inline">
            <Numbers :rvals="item[5][0]" :key="item[3]" :parent_id="item[3]" :lang="lang" :multiple="1" :label="item[2]"
                     @setRange="setRange($event)" />
          </div>
        </slot>
      </slot>
    </div>
  </div>
</template>

<script>
import {fetchBrands, fetchFilter } from "@/api";
import Checkbox from "@/components/elements/Checkbox";
import Radio from "@/components/elements/Radio";
import CheckboxImaged from "@/components/elements/CheckboxImaged";
import Numbers from "@/components/elements/Numbers";

export default {
  name: "ModelFilter",
  components: {Numbers, Radio, CheckboxImaged, Checkbox},
  props: {
    lang: String,
    MediaURL: String
  },
  data() {
    return {
      error: null,
      filterSettings: null,
      Brands: Array,
      loading: true,
      checkboxesO: {},
      radiosO: {},
      rangesO: {},
      brandsA: [],
      filterO: {},
    }
  },
  mounted() {
    // загружаем данные, когда представление создано
    // и данные реактивно отслеживаются
    fetchFilter().then((obj, err) => {
      this.loading = false
      if (err) {
        this.error = err.toString()
      } else {
        this.filterSettings = obj['data']
      }
    })
    fetchBrands().then((obj, err) => {
      this.loading = false
      if (err) {
        this.error = err.toString()
      } else {
        this.Brands = obj['data']
      }
    })
  },
  methods: {
    // {'reset': ['false'],
    // 'step': ['new'],
    // 'checkboxs': ['{"checkbox4":["3","781"]}'],
    // 'ranges': ['{"range218":[100,200000]}'],
    // 'radios': ['{"radio13":"8"}'],
    // 'brands': [2,4]}
    resetFilter() {
      this.brandsA = []
      this.radiosO = {}
      this.checkboxesO = {}
      this.rangesO = {}
    },
    onFilter() {
      this.filterO = {
        'brands': this.brandsA,
        'checkboxs': this.checkboxesO,
        'ranges': this.rangesO,
        'radios': this.radiosO
      }
      this.$emit('setFilter', this.filterO)
    },
    iChange(e) {
      this.$emit('inputChange', e)
    },
    setBrand(e) {
      const id = parseInt(e.target.id)
      if (this.brandsA.includes(id)) {
        const idx = this.brandsA.indexOf(id)
        this.brandsA.splice(idx, 1)
      } else {
        this.brandsA.push(id)
      }
      this.iChange(e)
      this.onFilter();
    },
    setRadio(e) {
      this.radiosO[e.target.getAttribute('name')] = parseInt(e.target.id)
      this.iChange(e)
      this.onFilter();
    },
    setCheckbox(e) {
      if (e.target.type === 'checkbox') {
        const key = 'checkbox'+e.target.getAttribute('name')
        const val = parseInt(e.target.id.replace('checkbox', ''))
        if (this.checkboxesO[key]) {
          if (this.checkboxesO[key].includes(val)) {
            const idx = this.checkboxesO[key].indexOf(val)
            this.checkboxesO[key].splice(idx, 1)
          } else {
            this.checkboxesO[key].push(val)
          }
        } else {
          this.checkboxesO[key] = [val]
        }
        this.iChange(e)
        this.onFilter();
      }
    },
    setRange(e) {
      const min = parseInt(e.target.getAttribute('min'))
      const max = parseInt(e.target.getAttribute('max'))
      const key = e.target.getAttribute('name')
      const val = parseInt(e.target.value)
      const id = e.target.id
      if (id.indexOf('min') > -1 && val >= min && val <= max) {
        if (this.rangesO[key]) {
          this.rangesO[key][0] = val
        } else {
          this.rangesO[key] = [val, max]
        }
      } else if(val >= min && val <= max) {
        if (this.rangesO[key]) {
          this.rangesO[key][1] = val
        } else {
          this.rangesO[key] = [min, val]
        }
      }
      this.iChange(e)
      this.onFilter()
    },
  },
}

</script>

<style scoped>
 .model_filter {
   width: 100%;
   grid-area: a-second;
   padding: .5em 0;
   max-height: 500px;
   overflow-y: scroll;
   overflow-x: hidden;
 }
 .filter_options {
   margin: 1em 0;
 }
 .filter_options label, .input_inline {
   margin: 0 0 0 0.5em;
 }
 .filter_brands {
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   grid-gap: .4em;
   max-height: 180px;
   overflow-y: scroll;
   overflow-x: hidden;
   width: calc(100% + 20px);
   padding: 0 20px 0 0;
   box-sizing: border-box;
 }
</style>
