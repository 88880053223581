<template>
  <button :id="iid" :style='css_style'>{{ label }}
    <slot v-if="val === '+'">
      <slot v-if="lang === 'ru'">есть</slot>
      <slot v-else>yes</slot>
    </slot>
    <slot v-if="val === '-'">
      <slot v-if="lang === 'ru'">нет</slot>
      <slot v-else>no</slot>
    </slot>
    <slot v-if="vmin">
      <slot v-if="lang === 'ru'">от </slot><slot v-else>from </slot> {{vmin}}
    </slot>
    <slot v-if="vmax">
      <slot v-if="lang === 'ru'"> до </slot><slot v-else> to </slot> {{vmax}}
    </slot>
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    label: String,
    iid: Number,
    multiple: String,
    val: String,
    lang: String,
    vmin: String,
    vmax: String
  },
  data() {
    return {
      css_style: ''
    }
  },
  mounted() {
    this.css_style += 'font-size:' + this.multiple + 'em;'
  },
}
</script>

<style scoped>
button {
  position: relative;
  cursor: pointer;
  border: 0;
  color: var(--white);
  background-color: var(--accent);
  border-radius: .3em;
  padding: .2em .8em .2em .4em;
  margin: 0 .5em 0 0;
}
button:hover {
  box-shadow: 0 0 4px 1px var(--accent);
}
button:after {
  font-family: "Material Icons Outlined";
  position: absolute;
  content: '\e5cd';
  top: 0.25em;
  right: .25em;
  padding: 0;
  line-height: .7em;
  font-size: .7em;
}
</style>
