<template>
  <div>
    <ul>
      <li @click="onMenuClick($event, 'models')"
          :class="{active:activeElement === `models`}"
      >Models</li>
      <li @click="onMenuClick($event, 'partcode')"
          :class="{active:activeElement === `partcode`}"
      >Partcodes</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Dsidebar",
  props: {
    lang: String
  },
  data() {
    return {
      activeElement: ''
    }
  },
  methods: {
    onMenuClick(e, val) {
      this.activeElement = val
      this.$emit('changeComponent', val)
    }
  }
}
</script>

<style scoped>
ul {
  list-style: none;
  padding: 0;
}

li {
  cursor: pointer;
  padding: .5em 0;
}
li:hover, li.active {
  color: var(--accent);
}
</style>
