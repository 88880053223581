<template>
  <label v-if="label" :for="id"> {{label}}
    <input :type="type" :placeholder="placeholder" :id="id" :name="id" :aria-label="label" :style='css_style' />
  </label>
  <input v-else :type="type" :placeholder="placeholder" :id="id" :name="id" :aria-label="label" :style='css_style' />
</template>

<script>
export default {
  name: "FilterInput",
  props: {
    label: String,
    placeholder: String,
    type: String,
    id: String,
    multiple: String
  },
  data() {
    return {
      css_style: ''
    }
  },
  mounted() {
    this.css_style += 'font-size:' + this.multiple + 'em;'
  },
  methods: {
    search(e) {
      this.$emit('searchItem', e.target.value)
    },
    aside() {
      this.$emit('showAside')
    }
  }
}

</script>

<style scoped>
  input {
    border-radius: 0.3em;
    border: 1px solid var(--text_base);
    padding: 0.4em 0.5em;
    line-height: 1em;
    width: 100%;
    min-width: 200px;
    display: inline-block;
    background-color: var(--bg_section);
    box-sizing: border-box;
    color: var(--input-text) !important;
  }
</style>
