<template>
  <div>
    <p class="text-center">© Part4 | <a href="mailto:info@part4.info" target="_blank">info@part4.info</a></p>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>
