<template>
  <h2>Models</h2>
</template>

<script>
export default {
  name: "Dmodels"
}
</script>

<style scoped>

</style>
