<template>
  <div class="dashboard_partcode">
    <div>
      <h2>Partcode</h2>
      <div>
        <Input v-on:keyup="onSearch($event)" v-model="valueSearch" type="text" :placeholder="`search partcode`"
               :multiple="`1.2`"/>
        <div v-if="SearchResult.length > 1" class="dashboard_partcode_search scroller">
          <div v-for="item in SearchResult" :key=item>
            <h4 @click="setPartcode" :option-id="item[2]" :option-value="item[5]">
              <slot v-if="item[5]">Part code: {{ item[5] }}</slot>
              <slot> {{ item[4] }} {{ item[3] }}</slot>
            </h4>
          </div>
        </div>
      </div>
      <button v-if="newPartcode" @click="addPartNew = !addPartNew" class="btn btn-default">Add new {{ newPartcode }}
      </button>
      <div v-if="addPartNew">
        <SelectSearch @setOption="setBrands($event)" :selectData="Brands" :label="`search brands`" :multiple="`1.2`"/>
        <SelectSearch @setOption="setModel($event)" :selectData="Models" :label="`search models`" :multiple="`1.2`"/>
        <SelectSearch @setOption="setModule($event)" :selectData="Modules" :label="`search module`" :multiple="`1.2`"/>
        <Input type="text" placeholder="img" v-model="img" :multiple="`1.2`"/>
        <Input type="text" placeholder="description_en" v-model="description_en" :multiple="`1.2`"/>
        <Input type="text" placeholder="description_ru" v-model="description_ru" :multiple="`1.2`"/>
        <SelectSearch @setOption="setDicPartcode($event)" :selectData="dicPartcodes" :label="`search partcode name`" :multiple="`1.2`"/>
        <Input type="text" placeholder="name_ru" v-model="name_ru" :multiple="`1.2`"/>
      </div>
    </div>
    <div v-if="addPartNew">
      <h2>add new <span class="btn btn-default" @click="savePartcode">save</span></h2>
      <h3 v-if="newPartcode"><small>Partcode:</small> {{ newPartcode }}</h3>
      <h3 v-if="valueBrand"><small>Brand:</small> {{ valueBrand }}</h3>
      <h3 v-if="models.length > 0"><small>Models: </small>
        <p class="models-select-item" v-for="(item, key) in models"
           :key="key" @click="removeModel(item[0], item[1])">{{ item[1] }}</p>
      </h3>
      <h3 v-if="valueModule"><small>Module:</small> {{ valueModule }}</h3>
      <h3 v-if="img"><small>img:</small> {{ img }}</h3>
      <h3 v-if="description_en"><small>description_en:</small> {{ description_en }}</h3>
      <h3 v-if="description_ru"><small>description_ru:</small> {{ description_ru }}</h3>
      <h3 v-if="name_en"><small>name_en:</small> {{ name_en }}</h3>
      <h3 v-if="name_ru"><small>name_ru:</small> {{ name_ru }}</h3>
      <label @click="isSupplies = !isSupplies" for="is_supplies">Supplies
        <input type="checkbox" id="is_supplies" name="is_supplies" @click="isSupplies = !isSupplies">
      </label>
    </div>
  </div>
</template>

<script>
import {fetchBrands, fetchDictionaryPartcodes, fetchModels, fetchModules, fetchPartcode, savePartcode, search} from "@/api";
import Input from "@/components/elements/Input";
import SelectSearch from "@/components/elements/SelectSearch";

export default {
  name: "Dpartcode",
  components: {SelectSearch, Input},
  props: {
    MediaURL: String,
    lang: String
  },
  data() {
    return {
      Brands: Array,
      SearchResult: Array,
      idBrand: null,
      valueBrand: null,
      Partcode: null,
      dicPartcodes: null,
      newPartcode: null,
      idPartcode: null,
      valuePartcode: null,
      idDicPartcode: null,
      Models: [],
      idModel: null,
      valueModel: null,
      Modules: [],
      idModule: null,
      valueModule: null,
      valueSearch: '',
      addPartNew: false,
      description_en: '',
      description_ru: '',
      name_ru: '',
      name_en: '',
      img: '',
      models: [],
      isSupplies: false
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      fetchBrands().then((obj, err) => {
        this.loading = false
        if (err) {
          this.error = err.toString()
        } else {
          this.Brands = obj['data']
        }
      });
      fetchDictionaryPartcodes().then((obj, err) => {
        this.loading = false
        if (err) {
          this.error = err.toString()
        } else {
          this.dicPartcodes = obj['data']
        }
      })
      fetchModules().then((obj, err) => {
        this.loading = false
        if (err) {
          this.error = err.toString()
        } else {
          this.Modules = obj['data']
        }
      });
      this.getModels()
    },
    getModels() {
      fetchModels(10000, 0, this.idBrand).then((obj, err) => {
        if (err) {
          this.error = err.toString()
          console.log(this.error)
        } else {
          this.Models = obj['data']
        }
      })
    },
    getPartcode(id) {
      fetchPartcode(id).then((obj, err) => {
        if (err) {
          this.error = err.toString()
        } else {
          this.Partcode = obj['data']
          console.log(obj)
        }
      })
    },
    setBrands(e) {
      this.idBrand = e.target.getAttribute('option-id')
      this.valueBrand = e.target.getAttribute('option-value')
      this.getModels();
      console.log(this.idBrand, this.valueBrand)
    },
    setModel(e) {
      this.idModel = e.target.getAttribute('option-id')
      this.valueModel = e.target.getAttribute('option-value')
      this.models.push([this.idModel, this.valueModel])
      console.log(this.models)
    },
    removeModel(id, val) {
      const idx = this.models.map(x => {
        console.log(x[0]);
        return x[0];
      }).indexOf(id)
      this.models.splice(idx, 1)
      console.log(idx, val)
    },
    setModule(e) {
      this.idModule = e.target.getAttribute('option-id')
      this.valueModule = e.target.getAttribute('option-value')
      console.log(this.idModule, this.valueModule)
    },
    setPartcode(e) {
      this.idPartcode = e.target.getAttribute('option-id')
      this.valuePartcode = e.target.getAttribute('option-value')
      this.getPartcode(this.idPartcode)
      console.log(this.idPartcode, this.valuePartcode)
    },
    setDicPartcode(e) {
      this.idDicPartcode = e.target.getAttribute('option-id')
      this.name_en = e.target.getAttribute('option-value')
    },
    onSearch(e) {
      search('partcode', e.target.value).then(obj => {
        this.SearchResult = obj['data']
        if (obj['data'] < 1) {
          this.newPartcode = e.target.value
        } else {
          this.newPartcode = null
        }
      })
    },
    savePartcode() {
      const data = {
        "partcode": this.newPartcode,
        "brand": this.idBrand,
        "description_en": this.description_en,
        "description_ru": this.description_ru,
        "name_en": this.name_en,
        "name_ru": this.name_ru,
        "dictionary_partcode_id": this.idDicPartcode,
        "img": this.img,
        "module": this.valueModule,
        "module_id": this.idModule,
        "models": this.models,
        "is_supplies": this.isSupplies,
      }
      savePartcode(data).then((obj, err) => {
        this.loading = false
        if (err) {
          this.error = err.toString()
        } else {
          console.log(obj['data'])
        }
      })
      console.log(data)
    }
  }
}
</script>

<style scoped>
.dashboard_partcode {
  display: grid;
  grid-template-columns: 30% 1fr;
  gap: 0.6em;
}

.dashboard_partcode div {
  display: grid;
  grid-template-rows: auto;
  gap: 0.6em;
  align-content: start;
  justify-content: start;
}

.dashboard_partcode_search {
  max-height: 300px;
}

.dashboard_partcode_search h4 {
  cursor: pointer;
}

button {
  font-size: 1.2em;
}

h3 {
  color: var(--accent);
}

h3 p {
  margin: 0;
}

h3 small {
  color: var(--white_black);
}

.models-select-item {
  cursor: pointer;
}
</style>
