<template>
  <div>
    <router-link to="/" class="logotype" />
  </div>

</template>

<script>
export default {
  name: "Header",
  props: {
    lang: String
  }
}
</script>

<style scoped>

</style>
