<template>
  <div id="to_up"><img v-bind:src="MediaURL + 'to_up.png'" alt="to_up"></div>
</template>

<script>
export default {
  name: "ToUp",
  props: {
    MediaURL: String,
  },
}
</script>

<style scoped>
#to_up {
  display: none;
  position: fixed;
  right: 15px;
  bottom: 15px;
  cursor: pointer;
}
#to_up.active {
  display: block;
}
</style>
