<template>
  <div class="btn" id="language_select" v-on:click="openSelector()">
    <img src="https://storage.yandexcloud.net/part4images/lang_icons/svg/228-russia.svg"
         v-on:click="changeLang('ru')" v-if="lang !== 'ru'" alt="en">
    <img src="https://storage.yandexcloud.net/part4images/lang_icons/svg/262-united-kingdom.svg"
         v-on:click="changeLang('en')" v-if="lang !== 'en'" alt="ru">
  </div>
</template>

<script>
export default {
  name: "LangBlock",
  props: {
    lang: String,
  },
  data(){
    return {
      isOpenSelector: false
    }
  },
  methods: {
    changeLang(e) {
      this.$emit('changeLang', e)
    },
    openSelector() {
      // this.isOpenSelector = !this.isOpenSelector
      this.isOpenSelector = false
    }
  }
}
</script>

<style scoped>
#language_select {
  position: relative;
  display: flex;
  align-items: center;
  justify-items: center;
}
img {
  height: 2em;
  width: auto;
}
</style>
