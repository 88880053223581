<template>
  <div class="btn">
    <span class="material-icons-outlined">account_box</span>
  </div>
</template>

<script>
export default {
  name: "AuthBlock"
}
</script>

<style scoped>

</style>
