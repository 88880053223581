<template>
  <div class="checkbox-imaged" :for="id" @click="toggleCheck" :style="'background-image: url('+MediaURL + image+')'"
       :aria-label="label" :id="id" :name="label"></div>
</template>

<script>
export default {
  name: "CheckboxImaged",
  props: {
    label: String,
    placeholder: String,
    type: String,
    id: Number,
    multiple: String,
    image: String,
    MediaURL: String
  },
  data() {
    return {
      css_style: ''
    }
  },
  mounted() {
    this.css_style += 'font-size:' + this.multiple + 'em;'
  },
  methods: {
    toggleCheck(e) {
      e.target.classList.toggle('active')
    }
  }
}

</script>

<style scoped>
  .checkbox-imaged {
    position: relative;
    font-size: 0;
    line-height: 0;
    border-radius: 6px;
    border: 2px solid var(--white_black);
    padding: 4px;
    background: var(--white_black);
    cursor: pointer;
    max-width: 100%;
    height: 60px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .checkbox-imaged:hover {
    border: 2px solid var(--white_black);
    box-shadow: 0 0 4px 1px var(--accent);
  }
  .checkbox-imaged.active {
    border: 2px solid var(--accent);
    box-shadow: 0 0 4px 1px var(--accent);
  }

</style>
