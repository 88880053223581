<template>
  <div class="models">
    <FilterBar :lang="lang" :inputT="inputT" />
    <Input v-if="lang === 'ru'" id="search_for_model" placeholder="Поиск по моделям" type="text" multiple="1"
           v-on:keyup="search"/>
    <Input v-if="lang === 'en'" id="search_for_model" placeholder="Search for models" type="text" multiple="1"
           v-on:keyup="search"/>

    <div v-if="loading" class="loading">
      <slot v-if="lang === 'ru'">Загрузка</slot>
      <slot v-if="lang === 'en'">Loading</slot>
      <LoadingAnimate/>
    </div>

    <div v-if="error" class="error">
      {{ error }}
    </div>

    <div class="models_list scroller" ref="scrollModel" v-on:scroll="onScroll">

      <div v-for="model in filteredModels" class="model_item" :id="model[0]" :key=model[0] v-on:click="goToModel(model[0])">
        <div class="image">
          <img v-bind:src="MediaURL + model[3]" v-if="model[3]" alt="image">
          <img v-bind:src="MediaURL + model[4]" v-if="!model[3] && model[4]" alt="image">
          <img v-bind:src="MediaURL + 'no_image.svg'" style="width: 100%;" v-if="!model[3] && !model[4]" alt="image">
        </div>
        <div class="desc">
          <h2>{{ model[1] }}</h2>
          <slot v-if="lang === 'ru' && model[10]">
            <span v-for="item in model[10]" :key="item">{{ item }}</span>
          </slot>
          <slot v-if="lang === 'en' && model[11]">
            <span v-for="item in model[11]" :key="item">{{ item }}</span>
          </slot>
          <slot v-if="model[8]">
            <p v-if="model[8][0]" class="price">
              <span v-if="lang === 'ru'">Цена: </span>
              <span v-if="lang === 'en'">Price: </span>
              <span><slot v-if="lang === 'en'">$&nbsp;</slot>
              {{ toPrice(model[8][0]) }}<slot v-if="lang === 'ru'">&nbsp;&#x20bd;</slot></span>
            </p>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import Input from "@/components/elements/FilterInput";
import {filter_models} from "@/api";
import LoadingAnimate from "@/components/elements/LoadingAnimate";
import FilterBar from "@/components/blocks/FilterBar";

export default {
  name: 'Models',
  props: {
    lang: {type: String},
    MediaURL: {type: String},
    size: {type: Number},
    reset: {type: String},
    step: {type: String},
    filter: {type: Object},
    inputT: {type: Event}
  },
  computed: {},
  data() {
    return {
      loading: false,
      post: null,
      error: null,
      models: null,
      scrollSize: 20,
      scrollOffset: null,
      scrollPos: 3000,
      filteredModels: [],
      filterO: null
    }
  },
  components: {
    FilterBar,
    LoadingAnimate,
    Input
  },
  created() {
    // загружаем данные, когда представление создано
    // и данные реактивно отслеживаются
    this.fetchData()
  },
  watch: {
    // при изменениях маршрута запрашиваем данные снова
    $route: 'fetchData',
    filter() {
      this.fetchData()
    },
  },
  methods: {
    fetchData() {
      this.error = this.post = null
      this.loading = true
      this.filterO = JSON.parse(JSON.stringify(this.filter))
      filter_models(this.filterO, this.reset, this.step).then((obj, err) => {
        this.loading = false
        if (err) {
          this.error = err.toString()
        } else {
          this.models = obj['data']
          this.setModels()
        }
      })
    },
    goToModel(id) {
      console.log(id)
    },
    setModels() {
      this.scrollOffset = this.size
      this.filteredModels = this.models.slice(0, this.size)
    },
    toPrice(val) {
      const prices = val.split(';')
      return prices[1]
    },
    search(e) {
      this.$emit('searchItem', e.target.value)
    },
    onScroll() {
      if (this.$refs.scrollModel.scrollTop > this.scrollPos) {
        this.scrollPos += 2000
        this.filteredModels.push.apply(this.filteredModels, this.models.slice(this.scrollOffset, this.scrollSize + this.size))
        this.scrollSize += 20
        this.scrollOffset += 20
      }
    }
  }
};
</script>

<style scoped>
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4em;
}

</style>
