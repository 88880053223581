import { createRouter, createMemoryHistory, createWebHistory } from 'vue-router';
import Models from '../views/Models.vue';
import Supplies from '../views/Supplies.vue';
import About from '../views/About.vue';

const isServer = typeof window === 'undefined';
const history = isServer ? createMemoryHistory() : createWebHistory();
const routes = [
    {
        path: '/',
        name: 'Models',
        component: Models,
        props: true,
    },
    {
        path: '/cabinet',
        name: 'Cabinet',
        component: Models,
        props: true,
    },
    {
        path: '/supplies',
        name: 'Supplies',
        component: Supplies,
        props: true,
    },
    {
        path: '/about',
        name: 'About',
        component: About,
        props: true,
    },
];

const router = createRouter({
    history,
    routes,
});

export default router;
