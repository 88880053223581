<template>
  <div class="dash_wrap">
    <header>
      <h1>Dashboard</h1>
    </header>
    <aside>
      <Dsidebar :lang="lang" @changeComponent="changeComponent($event)" />
    </aside>
    <main>
      <Dpartcode v-if="activeComponent===`partcode` || activeComponent===``" :MediaURL="MediaURL" :lang="lang" />
      <Dmodels v-if="activeComponent===`models`" />
    </main>
    <footer>
      <Footer :lang="lang"/>
    </footer>
  </div>
</template>

<script>
import Dsidebar from "@/components/dashboard/Dsidebar";
import Dpartcode from "@/components/dashboard/Dpartcode";
import Footer from "@/components/Footer";
import Dmodels from "@/components/dashboard/Dmodels";
export default {
  name: "Dashboard",
  components: {Dmodels, Dpartcode, Dsidebar, Footer},
  data() {
    return {
      lang: 'ru',
      SearchResult: [],
      message: '',
      MediaURL: 'https://storage.yandexcloud.net/part4images/images/',
      theme: 'dark-theme',
      cart_count: 0,
      filter: {},
      step: 'next',
      reset: 'false',
      size: 60,
      inputT: null,
      activeComponent: ''
    }
  },
  methods: {
    changeComponent(val) {
      this.activeComponent = val
    },
  }
}
</script>

<style scoped>
.dash_wrap {
  background-color: var(--bg_main);
  min-height: 100vh;
  display: grid;
  grid-template-columns: 280px auto min-content;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
        "header header rside"
        "aside main rside"
        "footer footer footer";
  grid-gap: 1em;
  align-items: start;
  padding: 2em 2em 0 2em;
  overflow-x: hidden;
}
</style>
