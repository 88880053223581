<template>
  <slot v-for="ra in rvals" :key="ra[0]">
    <input type="radio" :id="'radio' + ra['id']" :name="'radio' + parent_id" :aria-label="label" :style='css_style'/>
    <label :for="'radio' + ra['id']" :id="ra['id']" :name="'radio' + parent_id" :aria-label="label"
           :aria-describedby="ra['val']" @click="setRadio">
      <slot v-if="lang === 'ru'">
        <slot v-if="ra['val'] === '+'">есть</slot>
        <slot v-else>нет</slot>
      </slot>
      <slot v-if="lang === 'en'">
        <slot v-if="ra['val'] === '+'">yes</slot>
        <slot v-else>no</slot>
      </slot>
    </label>
  </slot>

</template>

<script>
export default {
  name: "Radio",
  props: {
    lang: String,
    label: String,
    parent_id: Number,
    rvals: Array,
    multiple: Number,
    image: String,
    MediaURL: String
  },
  emits: ['setRadio'],
  data() {
    return {
      css_style: '',
    }
  },
  mounted() {
    this.css_style += 'font-size:' + this.multiple + 'em;'
  },
  methods: {
    setRadio(e) {
      this.$emit('setRadio', e)
    }
  }
}

</script>

<style scoped>
label {
  display: inline-block;
  font-size: 1.1em;
  padding: 0 0.5em 0 2em;
  line-height: 1.8em;
  position: relative;
  cursor: pointer;
}

label:hover {
  color: var(--accent);
}

label:before {
  left: 0;
  top: 0.2em;
  position: absolute;
  content: '';
  background-color: var(--bg_main);
  border: 1px solid var(--text_base);
  border-radius: 50%;
  width: 1.2em;
  height: 1.2em;
}

label:hover:before {
  border: 1px solid var(--accent);
  background-position: center;
}

label::after {
  font-family: 'Material Icons';
  left: -0.055em;
  top: -0.025em;
  position: absolute;
  content: '';
  color: var(--white);
  font-size: 1.5em;
}

input:checked + label::after {
  color: var(--accent);
  content: 'radio_button_checked';
}

img {
  max-width: 100%;
  height: auto;
}

input {
  display: none;
}

</style>
