<template>
  <div :class="[{grid_main: !isAdmin}, theme]">
    <slot v-if="isAdmin">
      <Dashboard/>
    </slot>
    <slot v-else>
      <header>
        <Header :lang="lang"/>
        <SearchApp @searchItem="searchItem($event)" :message="message" :SearchResult="SearchResult"
                   :MediaURL="MediaURL" :lang="lang"/>
      </header>
      <aside>
        <Menu :lang="lang" :filter="filter"/>
        <ModelFilter @setFilter="setFilter($event)" @inputChange="inputChange($event)" :lang="lang" :filter="filter"
                     :MediaURL="MediaURL"/>
      </aside>
      <main>
        <router-view :lang="lang" :MediaURL="MediaURL" :filter="filter" :size="size" :reset="reset" :step="step"
                     :inputT="inputT"/>
      </main>
      <section class="right_side">
        <LangBlock @changeLang="changeLang($event)" :lang="lang"/>
        <CartBlock :cart_count="cart_count" :lang="lang"/>
        <AuthBlock :lang="lang"/>
        <ToUp :MediaURL="MediaURL"/>
      </section>
      <footer>
        <Footer :lang="lang"/>
      </footer>
    </slot>
  </div>
</template>

<script>
import {search} from './api';
import SearchApp from "./components/SearchApp.vue";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import LangBlock from "@/components/blocks/LangBlock";
import CartBlock from "@/components/blocks/CartBlock";
import AuthBlock from "@/components/blocks/AuthBlock";
import ToUp from "@/components/elements/ToUp";
import Menu from "@/components/Menu";
import ModelFilter from "@/components/blocks/ModelFilter";
import Dashboard from "@/views/Dashboard";

export default {
  name: 'App',
  components: {
    Dashboard,
    ModelFilter,
    Menu,
    ToUp,
    AuthBlock,
    CartBlock,
    LangBlock,
    Footer,
    Header,
    SearchApp
  },
  data() {
    return {
      lang: 'ru',
      SearchResult: [],
      message: '',
      MediaURL: 'https://storage.yandexcloud.net/part4images/images/',
      theme: 'dark-theme',
      cart_count: 0,
      filter: {},
      step: 'next',
      reset: 'false',
      size: 60,
      inputT: null,
      isAdmin: false
    }
  },
  mounted() {
    if (this.$router.currentRoute.value['fullPath'] === '/?dashboard') {
      this.isAdmin = true
    }
  },
  methods: {
    changeLang(val) {
      this.lang = val
    },
    inputChange(e) {
      this.inputT = e
    },
    setFilter(f_arr) {
      this.filter = f_arr
    },
    searchItem(val) {
      this.message = val
      search('partcode', val).then(obj => {
        this.SearchResult = obj['data']
      })
    },
  }
}

</script>

<style>

</style>
